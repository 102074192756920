<template>
  <div v-loading="loading"
       element-loading-text="数据加载中...">
    <div class="header">
      <div class="header_top">
        <img src="../../assets/user.png"
             alt />
        <span class="center">人资管理</span>
        <img src="../../assets/right.png"
             alt />
        <span class="center">员工列表</span>
      </div>
    </div>
    <div style="display: flex; align-items: center;justify-content:space-between;margin:10px 0">
      <div>
        <span class="seach_text">关键字:</span>
        <el-input placeholder="请输入姓名、身份证号码、手机号"
                  v-model="formInline.keywords"
                  size="mini"
                  style="margin-right: 10px; width: 250px"
                  prefix-icon="el-icon-search"
                  clearable></el-input>
        <span class="seach_text">认证状态：</span>

        <el-select size="mini"
                   v-model="authenticateState"
                   placeholder="请选择"
                   @change="query"
                   clearable>
          <el-option :label="item.label"
                     :value="item.value"
                     v-for="(item, i) in authenList"
                     :key="i"></el-option>
        </el-select>
        <span class="seach_text"
              style="margin:0 10px">银行卡状态：</span>
        <el-select size="mini"
                   v-model="erleihuState"
                   placeholder="请选择"
                   @change="queryerleihu"
                   clearable>
          <el-option :label="item.label"
                     :value="item.value"
                     v-for="(item, i) in erleihuStateList"
                     :key="i"></el-option>
        </el-select>
        <!-- <span class="seach_text" style="margin:0 10px">角色：</span>
        <el-select
          size="mini"
          v-model="roleState"
          placeholder="请选择"
          @change="queryRoleState"
          clearable
        >
          <el-option
            :label="item.label"
            :value="item.value"
            v-for="(item, i) in roleStateList"
            :key="i"
          ></el-option>
        </el-select>-->
        <el-button style="margin: 0 10px"
                   type="primary"
                   size="small"
                   @click="seachBtn">查询</el-button>
      </div>
      <el-badge :value="bmdList.length"
                class="item"
                style="margin:0 10px">
        <el-button type="primary"
                   size="small"
                   @click="dialogVisible = true">
          <i class="el-icon-success"
             style="color: #fff; margin-right: 10px"></i>已选中
        </el-button>
      </el-badge>
      <el-dialog title="已选中"
                 :visible.sync="dialogVisible"
                 width="80%"
                 :before-close="handleClose">
        <bmdFilter v-on:del="delList"
                   :nameList.sync="bmdList"></bmdFilter>
        <div class="title">姓名、手机号、身份证号、银行卡号为必选项</div>
        <span slot="footer"
              class="dialog-footer">
          <span>已选择 {{ bmdList.length }}条数据</span>
          <div>
            <el-button type="primary"
                       @click="exportAll()">导出</el-button>
            <el-button style="margin-left: 30px;"
                       @click="cancel()">取 消</el-button>
          </div>

          <el-button type="text"
                     @click="delALL()"
                     style="color:red">清空员工列表数据</el-button>
        </span>
      </el-dialog>
    </div>
    <el-table :data="list"
              style="width: 100%">
      <el-table-column label="姓名"
                       label-class-name="arrClass">
        <template slot-scope="scope">
          <p style="margin-left: 20px">
            <el-row :gutter="20"
                    type="flex"
                    align="middle">
              <el-col :span="20"
                      class="col_left">
                <span class="clickColor"
                      @click="goDetail({ code: scope.row.code })">
                  {{
                  scope.row.name ||
                  scope.row.nickname
                  }}
                </span>
              </el-col>
            </el-row>
          </p>
        </template>
      </el-table-column>

      <el-table-column label="手机号">
        <template slot-scope="scope">
          <div v-if="scope.row.username"
               class="clickColor">
            <span v-if="scope.row.usernameShow"
                  @click="scope.row.usernameShow = false">
              {{
              scope.row.username.substr(0, 3) + new Array(scope.row.username.length -
              7).join('*') + scope.row.username.substr(-4)
              }}
            </span>
            <span v-else
                  @click="scope.row.usernameShow = true">{{ scope.row.username }}</span>
          </div>
          <span v-else>--</span>
        </template>
      </el-table-column>
      <el-table-column label="身份证号">
        <template slot-scope="scope">
          <div v-if="scope.row.idCardNumber"
               class="clickColor">
            <span v-if="scope.row.idCardNumberShow"
                  @click="scope.row.idCardNumberShow = false">
              {{
              scope.row.idCardNumber.substr(0, 3) + new Array(scope.row.idCardNumber.length -
              7).join('*') + scope.row.idCardNumber.substr(-4)
              }}
            </span>
            <span v-else
                  @click="scope.row.idCardNumberShow = true">{{ scope.row.idCardNumber }}</span>
          </div>
          <span v-else>--</span>
        </template>
      </el-table-column>
      <el-table-column label="角色"
                       v-if="!enterpriseName.includes('平台')">
        <template slot-scope="scope">
          <el-tag>
            <span>{{scope.row.role_e_name}}</span>
          </el-tag>
        </template>
      </el-table-column>
      <!-- <el-table-column label="银行卡号">
        <template slot-scope="scope">
          <div v-if="scope.row.bankCard">{{ scope.row.bankCard.bankName }}</div>
          <div class="clickColor" v-if="scope.row.bankCard">
            <span v-if="scope.row.show" @click="scope.row.show = false">
              {{
              scope.row.bankCard.accountCode.substr(0, 3) + new
              Array(scope.row.bankCard.accountCode.length - 7).join('*') + scope.row.bankCard.accountCode.substr(-4)
              }}
            </span>
            <span v-else @click="scope.row.show = true">{{ scope.row.bankCard.accountCode }}</span>
          </div>
          <span v-else class="clickColor">--</span>
        </template>
      </el-table-column>-->
      <el-table-column label="认证状态">
        <template slot-scope="scope">
          <span class="Authenticated"
                v-if="scope.row.isAuthenticated">已认证</span>
          <span class="isAuthenticated"
                v-if="!scope.row.isAuthenticated">未认证</span>
        </template>
      </el-table-column>
      <el-table-column label="注册时间">
        <template slot-scope="scope">
          <span>{{ scope.row.create_time }}</span>
        </template>
      </el-table-column>
      <!-- <el-table-column label="是否验收人员">
        <template slot-scope="scope">
          <el-switch
            v-model="scope.row.isChecker"
            @change="checkerChange(scope.row)"
            v-if="scope.row.roles != 'r6'"
            active-text="是"
            inactive-text="否"
          ></el-switch>
        </template>
      </el-table-column>-->
      <el-table-column label="操作"
                       width="300px">
        <template slot-scope="scope">
          <el-button type="text"
                     @click="addNameList(scope.row)"
                     v-if="scope.row.username&&scope.row.isAuthenticated">选中导出</el-button>
          <!-- <el-button type="text" @click="distributionEnterprise(scope.row)">分配企业</el-button> -->
          <el-button type="text"
                     v-if="scope.row.bankCard.length"
                     @click="seeBank(scope.row)">查看银行卡</el-button>
          <!-- v-if="scope.row.isAuthenticated && scope.row.bankCard.accountCode" -->
          <el-button type="text"
                     v-access
                     data-ctrl="modifyUserRoles"
                     v-if="scope.row.isAuthenticated"
                     @click="setRole(scope.row.code, scope.row.role_e_code)">设置角色</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog :visible="bankVisible"
               width="600px"
               :show-close="true"
               @close="bankVisible=false">
      <div slot="title"
           style="text-align: center;font-weight:700;font-size:16px">
        <span>银行卡信息</span>
        <div style="text-align: center;font-weight:700;color:#999;font-size:14px">{{name}}</div>
      </div>
      <div class="bank_class"
           v-for="(item,index) in bankList"
           :key="index">
        <div>开户行：{{item.bankName}}</div>
        <div>银行卡号:{{item.accountCode}}</div>
      </div>
      <div slot="footer"
           style="text-align: center">
        <el-button type="primary"
                   @click="bankVisible=false">确 定</el-button>
      </div>
    </el-dialog>
    <DistributionE :distributionVisible.sync="distributionVisible"
                   :name.sync="name"
                   :title.sync="title"
                   :code.sync="code"></DistributionE>
    <AddRole :dialogFormVisible.sync="dialogFormVisible"
             @close="close()"
             :setCode.sync="setCode"
             :setRoles.sync="setRoles"></AddRole>
    <div class="pagination">
      <span class="clickColor">共{{ total }}条</span>
      <span class="clickColor"
            style="margin: 0 10px">每页{{ pageSize }}条</span>
      <el-pagination background
                     :current-page.sync="pageNumber"
                     @size-change="handleSizeChange"
                     @current-change="handleCurrentChange"
                     :page-size="pageSize"
                     prev-text="上一页"
                     next-text="下一页"
                     layout="prev,pager, next,jumper"
                     :total="total"></el-pagination>
    </div>
  </div>
</template>

<script>
import { userList, api } from '../../api';
import moment from 'moment';
import { parseJsonToTable } from '/src/lib/dev';
import * as JSZip from 'jszip';
import * as axios from 'axios';
import Vue from 'vue';
// 编辑用户信息
export const userEdit = api()('user.edit.json')

export const registrationState = api()(
  'user.change.jx.tax.registration.state.json',
);
export const zipAndDownloadRemoteFile = async (zipName, files) => {
  var zip = new JSZip();
  const total = files.length;
  var root = zip.folder(zipName);
  const loading = Vue.prototype.$loading({
    lock: true,
    text: `正在加载资源 (1/${total})完成后将自动导出 请耐心等候...`,
    spinner: 'el-icon-loading',
    background: 'rgba(0, 0, 0, 0.7)',
  });
  for (let index = 0; index < files.length; index++) {
    loading.setText(
      `正在加载资源 (${index + 1}/${total})完成后将自动导出 请耐心等候...`,
    );
    const file = files[index];
    const fileFolder = file.folder ? root.folder(file.folder) : root;
    if (file.contentType === 'url' && file.content) {

      const { data } = await axios.get(file.content, {
        responseType: 'blob',
      });
      fileFolder.file(file.name, data);
    }
    if (file.contentType === 'blob') {
      fileFolder.file(file.name, file.content);
    }
  }
  loading.setText('正在下载...');
  const content = await zip.generateAsync({
    type: 'blob',
  });
  var reader = new FileReader();
  reader.readAsDataURL(content);
  reader.onload = function (e) {
    var a = document.createElement('a');
    a.download = zipName + '.zip';
    a.href = e.target.result;
    a.click();
    loading.close();
  };
};
import { mapActions, mapMutations, mapState } from 'vuex';

export default {
  components: {
    bmdFilter: () => import('./bmdList'),
    AddRole: () => import('../addRole.vue'),
    DistributionE: () => import('./distributionE.vue')
  },
  data () {
    return {
      dialogFormVisible: false,
      authenticateState: '',
      erleihuState: '',
      enterpriseName: '',
      roleState: '',
      options: [
        {
          label: '',
          value: '',
        },
      ],
      authenList: [
        {
          label: '全部',
          value: '',
        },
        {
          label: '未认证',
          value: '0',
        },
        {
          label: '已认证',
          value: '1',
        },
      ],
      erleihuStateList: [
        {
          label: '全部',
          value: '',
        },
        {
          label: '已有二类户',
          value: true,
        },
        {
          label: '未开二类户',
          value: false,
        },
      ],
      roleStateList: [
        {
          label: '全部',
          value: '',
        },
        {
          label: '超级管理员',
          value: 'r2_admin',
        },
        {
          label: '管理员',
          value: 'r2',
        },
        {
          label: '财务',
          value: 'r3',
        },
        {
          label: '人力资源',
          value: 'r5',
        },
        {
          label: '企业员工',
          value: 'r6',
        },
        {
          label: '游客',
          value: 'r-1',
        },
      ],

      setCode: '',
      setRoles: '',
      pageSize: 10,
      pageNumber: 1,
      total: 0,
      formInline: {
        keywords: '',
        createTime: '',
        isAuthenticated: '',
        minCreateTime: '',
        maxCreateTime: '',
        auditStatus: '',
      },
      list: [],
      payPlatforms: [],
      dialogVisible: false,
      addDialogVisible: false,
      editDialogVisible: false,
      distributionVisible: false,
      dialogData: null,
      bmdList: [],
      code: '',
      name: '',
      title: '',
      isEnterpriseSuperAdmin: null,
      loading: true,
      bankVisible: false,
      bankList: []
    };
  },

  async created () {
    this.bmdList = this.$store.state.badMaker.nameList;
    this.enterpriseName = window.localStorage.getItem('enterpriseName')
    console.log(this.roles);
    this.payPlatforms = this.$route.params.$preload.platformList;
    const queryParams = this.$route.query;
    if (queryParams.isAuth) {
      this.formInline.isAuthenticated = queryParams.isAuth * 1;
    }
    if (queryParams.time && queryParams.time === '1') {
      this.formInline.minCreateTime = moment()
        .startOf('day')
        .format('YYYY-MM-DD HH:mm:ss');
      this.formInline.maxCreateTime = moment().format('YYYY-MM-DD HH:mm:ss');
    }
    if (queryParams.time && queryParams.time === '2') {
      this.formInline.minCreateTime = moment()
        .startOf('month')
        .format('YYYY-MM-DD HH:mm:ss');
      this.formInline.maxCreateTime = moment().format('YYYY-MM-DD HH:mm:ss');
    }
    this.loadData();

  },
  computed: {
    ...mapState('badMaker', ['nameList', 'objList']),
  },
  methods: {
    // keywords () {
    //   // this.pageNumber = 1;
    //   // this.loadData();
    // },
    ...mapMutations('badMaker', ['setNameList']),
    ...mapActions('badMaker', ['setNameList']),
    seeBank (row) {
      this.bankVisible = true
      this.name = row.name || row.nickname
      this.bankList = row.bankCard

    },
    delList (a) {
      this.bmdList = a

    },
    setRole (code, roles) {
      this.setCode = code
      this.setRoles = roles

      this.dialogFormVisible = true
    },
    addNameList (row) {
      row['disabled'] = false
      console.log(this.bmdList, row);
      if (this.bmdList.find((g) => g.code === row.code)) {
        this.bmdList.some((goods) => {
          if (goods.code == row.code) {
            this.$message({
              message: '此人已加入名单',
              type: 'warning',
            });
          }
        });
      } else {
        this.bmdList.push(row);

        this.setNameList(this.bmdList);
      }

    },
    modifyState (row) {

      let state = '';
      if (row.jxTaxRegistrationState == '已登记') {
        state = '未登记';
      } else {
        state = '已登记';
      }
      this.$confirm('此操作将修改税务登记状态, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          const params = {
            code: row.code,
            jxTaxRegistrationState: state,
          };
          registrationState(params).then(() => {
            this.$message({
              type: 'success',
              message: '修改成功!',
            });
            this.loadData();
          });
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消',
          });
        });
    },
    distributionEnterprise (row) {
      this.code = row.code
      this.title = '设置'
      this.name = row.name
      this.distributionVisible = true
    },

    close () {
      this.dialogVisible = false;
      this.distributionVisible = false;
      this.loadData();
    },
    handleSizeChange (val) {
      this.loading = true;
      this.pageSize = val;
      this.loadData();
    },
    handleCurrentChange (val) {
      this.loading = true;
      this.pageNumber = val;
      this.loadData();
    },
    handleClose () {
      this.dialogVisible = false;
      this.bmdList = this.$store.state.badMaker.nameList;
    },

    async loadData () {
      var params = {}
      if (this.roleState === 'r2_admin') {
        params = {
          pageSize: this.pageSize,
          pageNumber: this.pageNumber,
          keywords: this.formInline.keywords,
          roles: 'r2',
          isAuthenticated: this.authenticateState,
          hasAccount: this.erleihuState,
          isEnterpriseSuperAdmin: this.isEnterpriseSuperAdmin
        };
      }

      else {
        params = {
          pageSize: this.pageSize,
          pageNumber: this.pageNumber,
          keywords: this.formInline.keywords,
          roles: this.roleState,
          isAuthenticated: this.authenticateState,
          hasAccount: this.erleihuState,
          isEnterpriseSuperAdmin: this.isEnterpriseSuperAdmin
        };
      }
      const res = await userList(params)
      this.list = res.list.map(it => {
        return {
          ...it,
          bankCard: it.bankCard || { accountCode: '' },
          show: !!it.bankCard,
          usernameShow: true,
          idCardNumberShow: true
        }
      })

      this.total = res.total;
      this.loading = false;
    },

    addObject () {
      this.addDialogVisible = true;
    },
    goEdit (obj) {
      this.dialogData = obj;
      this.editDialogVisible = true;
    },
    goDetail (obj) {
      this.$router.push({
        path: '/system/user/detail',
        query: obj,
      });
    },

    addClose () {
      this.addDialogVisible = false;
      this.loadData();
    },

    putAway () {
      Object.keys(this.formInline).forEach((it) => {
        this.formInline[it] = '';
      });
      this.pageNumber = 1;
      this.loadData();
    },
    query (val) {
      this.authenticateState = val
      console.log(val);

    },
    queryerleihu (val) {
      this.erleihuState = val
      console.log(val);
    },
    queryRoleState (e) {
      this.isEnterpriseSuperAdmin = null
      if (e === 'r6') {
        this.roleState = 'r6'
      }
      else if (e === 'r2_admin') {
        this.roleState = 'r2_admin'
        this.isEnterpriseSuperAdmin = true
      }
      else if (e === 'r2') {
        this.roleState = 'r2'
        this.isEnterpriseSuperAdmin = false
      }

      else {
        this.roleState = e

      }
      console.log(e);
    },
    async seachBtn () {
      this.pageNumber = 1
      this.loadData()
      // const res = await userList(params)
      // console.log(res);
      // this.list = res.list.map(it => {
      //   return {
      //     ...it,
      //     bankCard: it.bankCard || { accountCode: '' },
      //     show: !!it.bankCard,
      //     usernameShow: true,
      //     idCardNumberShow: true
      //   }
      // })
      // this.total = res.total;
      // this.loading = false;
    },
    // downLoad(command) {
    //   let list = this.list;
    //   let subName = "全部";
    //   if (command === "isAuth") {
    //     subName = "已认证";
    //     list = list.filter(it => it.isAuthenticated);
    //   }
    //   const _list = list.map(it => {
    //     return {
    //       ...it,
    //       isAuthenticated: it.isAuthenticated ? "已认证" : "未认证"
    //     };
    //   });

    //   const blob = parseJsonToTable(
    //     `用户列表-${subName}`,
    //     {
    //       姓名: "name",
    //       手机号: "phone",
    //       用户名: "username",
    //       身份证号: "idCardNumber",
    //       // 身份证正面: "idCardFront",
    //       // 身份证背面: "idCardBack",
    //       银行: "bankCard_bankName",
    //       银行卡号: "bankcode",
    //       认证情况: "bankCard_accountCode"
    //     },
    //     _list
    //   );
    //   const zipList = [
    //     {
    //       name: "名单.xlsx",
    //       contentType: "blob",
    //       content: blob
    //     }
    //   ];
    //   _list.forEach(it => {
    //     zipList.push(
    //       {
    //         name: "头像面.png",
    //         folder: it.name,
    //         contentType: "url",
    //         content: it.idCardFront?.replace("https", "http")
    //       },
    //       {
    //         name: "国徽面.png",
    //         folder: it.name,
    //         contentType: "url",
    //         content: it.idCardBack?.replace("https", "http")
    //       }
    //     );
    //   });

    //   zipAndDownloadRemoteFile(
    //     moment().format("MMMM Do YYYY, h:mm:ss a"),
    //     zipList
    //   ).then(() => {});
    // },
    cancel () {
      this.dialogVisible = false;
      this.bmdList = this.$store.state.badMaker.nameList;
    },
    delALL () {
      this.dialogVisible = false;
      var b = [];
      this.setNameList(b);
      this.bmdList = this.$store.state.badMaker.nameList;

    },

    exportAll () {

      const obj = {
        r2: '管理员',
        r3: '财务',
        r5: '人力资源',
        r6: '企业员工'
      }
      console.log(this.$store.state.badMaker.nameList);
      const _list = this.$store.state.badMaker.nameList.map((it) => {
        return {
          ...it,
          isAuthenticated: it.isAuthenticated ? '已认证' : '未认证',
          roles: obj[it.roles]
        };
      });
      console.log(this.objList);
      parseJsonToTable(
        '用户列表',
        {
          ...this.objList,
          '银行卡号(二类户)': 'bankCard.accountCode'
        },
        _list,
        true,
      );
      this.dialogVisible = false;
      this.bmdList = this.$store.state.badMaker.nameList;
    },
    async checkerChange (row) {
      await userEdit({ code: row.code, isChecker: row.isChecker });
      this.$message.success('设置成功')
      this.loadData()
    }
  },
};
</script>
<style>
/* .el-table th.is-leaf:nth-last-child(2) {
  text-align: center !important;
}

.el-table_1_column_1 .arrClass .is-leaf {
  margin-left: 40px;
} */
</style>
<style lang="scss" scoped>
.clickColor {
  color: #409eff;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

.summa {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.el-form-item {
  margin-top: 12px !important;
  margin-bottom: 10px;
}

.Authenticated {
  margin: 10px;
  padding: 2px 4px;
  background: #129dd8;
  border-radius: 5px;
  color: #fff;
}

.isAuthenticated {
  margin: 10px;
  padding: 2px 4px;
  // border: 1px solid #797979;
  background: #c3c2c2;
  border-radius: 5px;
  color: #fff;
}

.col_left {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.dialog-footer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  span {
    font-size: 14px;
  }
}

.title {
  width: 20%;
  font-size: 14px;
  color: #c3c2c2;
  float: right;
}
.bank_class {
  padding: 10px 0;
  border-bottom: 1px solid #999;
}
.bank_class:last-child {
  border: none;
  margin-top: 10px;
}
</style>
